import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not Found" />
    <h1>Not Found</h1>
    <p>
      You just hit a route that doesn&#39;t exist... the sadness{' '}
      <span role="img" aria-label="disappointed emoji">
        😥
      </span>
    </p>
  </Layout>
)

export default NotFoundPage
